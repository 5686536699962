import axios from '@/axios.js'

export default {
  fetchCompanies ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/company')
        .then(({data: response}) => {
          commit('SET_COMPANIES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchCompany (context, companyId) {
    return new Promise((resolve, reject) => {
      axios.get(`/company/${companyId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeCompany (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/company', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateCompany (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/company/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyCompany ({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      axios.delete(`company/${companyId}`)
        .then((response) => {
          commit('REMOVE_RECORD', companyId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
