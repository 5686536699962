<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add Company</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="6">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Company Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              type="email"
              class="w-full"
              label="E-mail Address (*)"
              v-model="payload.email"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("email")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Phone Number (*)"
              v-model="payload.telp"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("telp")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Whatsapp (*)"
              v-model="payload.whatsapp"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("telp")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <span class="mb-1 ml-2 block text-sm">Address (*)</span>
            <vs-textarea v-model="payload.address" />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("address")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>

      <vs-col vs-w="6" class="pl-10 pt-6">
        <div class="flex items-start flex-col sm:flex-row">
          <img :src="url" class="rounded mr-4" width="250" height="250" />
          <div>
            <input
              type="file"
              @change="addImage"
              class="hidden"
              ref="update_logo_input"
              accept="image/*"
            />
            <vs-button
              @click="$refs.update_logo_input.click()"
              class="mr-4 mb-2"
              type="filled"
              icon-pack="feather"
              icon="icon-edit"
            ></vs-button>
            <vs-button
              @click="removeImage"
              type="border"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
            ></vs-button>
          </div>
        </div>
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("logo")
        }}</span>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
// Store Module
import moduleCompanyManagement from "@/store/company-management/moduleCompanyManagement.js";

export default {
  name: "CompanyAdd",

  metaInfo: {
    title: "Add Company",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  watch: {
    url(url) {
      if (url == null) {
        this.url = "https://via.placeholder.com/250";
      } else {
        this.url = url;
      }
    },
  },

  data: () => ({
    payload: {
      name: "",
      email: "",
      telp: "",
      address: "",
      logo: "",
      whatsapp: "",
    },
    url: null,
  }),

  methods: {
    addImage(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.payload.logo = file;
    },

    removeImage() {
      this.url = null;
      this.payload.logo = "";
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);
      formData.set("email", this.payload.email);
      formData.set("telp", this.payload.telp);
      formData.set("address", this.payload.address);
      formData.set("whatsapp_number", this.payload.whatsapp);
      if (this.payload.logo !== "") {
        formData.set("logo", this.payload.logo);
      }

      this.$store
        .dispatch("companyManagement/storeCompany", formData)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success",
          });

          this.$router.push("/companies");
        })
        .catch((err) => {
          this.$catchErrorResponse(err);
        });
    },
  },

  mounted() {
    if (this.url == null) {
      this.url = "https://via.placeholder.com/250";
    }
  },

  created() {
    if (!moduleCompanyManagement.isRegistered) {
      this.$store.registerModule("companyManagement", moduleCompanyManagement);
      moduleCompanyManagement.isRegistered = true;
    }
  },
};
</script>

<style></style>
