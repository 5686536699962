import state from './moduleCompanyManagementState.js'
import mutations from './moduleCompanyManagementMutations.js'
import actions from './moduleCompanyManagementActions.js'
import getters from './moduleCompanyManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

